



















































































import Vue from "vue";
import Header from "@/components/layout/SearchHeader.vue";

export default Vue.extend({
  name: "About",
  components: {
    Header,
  },
  metaInfo: {
    title: "About us - Einander Helfen",
    link: [{ rel: "canonical", href: "https://einander-helfen.org/about" }],
  },
});
